import { css } from 'styled-components';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
export default css`
  h3 {
    color: ${colors.bodyText};
  }
  p {
    color: #fff;
  }
  .ps-widget.ps-enabled {
    margin: 0 1em 10px;
    text-align: center;
    height: 43px;
    width: 13em;
    position: relative;
    background: ${colors.primary};
    font-size: 20px;
    border: 0;
    margin: 0 1em;
    &:hover {
      background: ${colors.primaryHover};
      font-family: ${fonts.secondary}, sans-serif;
      color: white;
      border: 0;
      font-size: 20px;
      .ps-button-label:hover {
        color: ${colors.primary};
      }
    }
    &:after {
      content: 'Find';
      color: #fff;
      position: absolute;
      right: 81.0315px;
      top: 5px;
    }
  }
  .ps-button-label {
    display: none !important;
  }
  .ps-widget[ps-sku].ps-disabled > small {
    color: #fff;
    font-family: ${fonts.secondary}, sans-serif;
    &:hover {
      font-family: ${fonts.secondary}, sans-serif;
      color: #fff;
    }
  }
  .ps-widget[ps-sku] {
    margin: 0 1em;
  }
  .ps-widget[ps-sku].ps-disabled {
    margin: 0 1em;
    background: rgb(200, 200, 200);
    margin: 0 1em 10px;
    border: 0;
    &:hover {
      font-family: ${fonts.primary}, sans-serif;
      background: rgb(200, 200, 200);
      color: white;
      border: 0;
    }
  }

  .ps-widget[ps-sku],
  .ps-widget[data-ps-sku] {
    font-family: ${fonts.secondary}, sans-serif;
    font-size: 20px;
    display: inline-block;
    float: none;
    justify-content: center;
    max-width: 200px;
    margin: 0 1em;
  }

  .options {
    justify-content: center;
    select {
      margin: 0 1em 10px;
    }
  }
  svg {
    color: ${colors.primary};
  }
  .moduleHeader {
    color: ${colors.primary};
    font-family: ${fonts.primary};
  }
  .moduleSubHeadline {
    font-size: 22px;
  }
  .content {
    padding: 40px 15px;
    text-align: center
    select::-ms-expand {
      display: none;
    }
  }
  select {
    padding-left: 10px;
  }
`;
