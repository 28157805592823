import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import RegularButton from './RegularButton';
import ComponentStyles from '../brand-styles/components/ProductCategoryCarousel.styles';
import { breakpoints, colors, fonts } from '../style-utilities/variables';

import StyledLink from './StyledLink';

const StyledProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  color: gray;
  margin: auto;
  text-align: center;
  .category-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h4 {
    padding: 0 50px;
  }
  h3 {
    margin: 0 40px;
  }

  &:hover {
    bottom: 10px;
    position: relative;
    transition: bottom 2s ease-out;
  }
  img {
    height: 250px;
    width: 250px;
    margin-top: 20px;
    object-fit: cover;
  }
`;
const StyledCarousel = styled.div`
  /* padding: 30px; */
  .swiper-container {
    min-height: 400px;
  }
  .Button {
    text-align: center;
  }
  a{
    text-decoration:none;
  }
 .productName{
      color: ${colors.primary};
      margin-bottom:1em;
      font-size:32px;
    }
    .swiper-pagination-bullet-active {
    background: ${colors.primary};
    outline: none;
  }
  .productDescription {
    color: ${colors.black};
    margin: 0 30px !important;
  }
  .swiper-pagination-bullets {
    top: 23.3em;
  }
  .prev-button, .next-button {
    display: none;
  }
  @media (min-width: ${breakpoints.mobileMax}) {
    .swiper-pagination {
      display: none;
    }
    .prev-button, .next-button {
    display: block;
  }
  }
  ${StyledProduct}
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 47%;
  right: ${props => props.right};
  left: ${props => props.left};
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-color: transparent;
  color: #d2d4dc;
  font-size: 40px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.desktop}) {
    top: 45%;
  }
`;

class ProductCategoryCarousel extends Component {
  state = {
    productCategories: [],
    isBrowser: undefined
  };

  BASE_URL = `${process.env.WP_BASE_URI}/${
    process.env.ACTIVE_BRAND
  }/wp-json/wp/v2/`;

  componentDidMount() {
    const { carouselItems } = this.props;
    const slugs = carouselItems.map(el => el.slug).join(',');
    fetch(`${this.BASE_URL}product_line?slug=${slugs}`)
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({
          productCategories: data,
          isBrowser: typeof window !== 'undefined'
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    const params = {
      // slidesPerView: 1,
      loop: true,
      // centeredSlides: true,
      type: 'bullets',
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.next-button',
        prevEl: '.prev-button'
      },
      renderNextButton: () => (
        <NavigationButton className="next-button" right="0">
          <FontAwesomeIcon icon={faAngleRight} />
        </NavigationButton>
      ),
      renderPrevButton: () => (
        <NavigationButton className="prev-button" left="0">
          <FontAwesomeIcon icon={faAngleLeft} />
        </NavigationButton>
      ),
      breakpoints: {
        2500: {
          slidesPerView: 5
        },
        1800: {
          slidesPerView: 4
        },
        1300: {
          slidesPerView: 3
        },
        1050: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 1
        }
      }
    };

    return (
      <StyledCarousel>
        {this.props.children}
        {this.state.productCategories.length > 0 ? (
          <Swiper {...params}>
            {this.state.productCategories.map(
              ({ acf, slug, name, description }) => {
                return (
                  <StyledProduct key={acf.thumbnail.id}>
                    <Link to={`/product-categories/${slug}`}>
                      <img src={acf.menu_image.source_url} alt="placeImg" />
                      <p className="productName">{ReactHtmlParser(name)}</p>
                    </Link>
                    <p className="productDescription">
                      {ReactHtmlParser(acf.sub_headline)}
                    </p>
                  </StyledProduct>
                );
              }
            )}
          </Swiper>
        ) : (
          <div>...Loading</div>
        )}
        <Link to={`./products`}>
          <RegularButton link="/products">See All Products</RegularButton>
        </Link>
      </StyledCarousel>
    );
  }
}

export default ProductCategoryCarousel;
