import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import ComponentStyles from '../brand-styles/pages/index.styles';
import RegularButton from '../components/RegularButton';
import { breakpoints, colors } from '../style-utilities/variables';

// import components
import ProductCarousel from '../components/ProductCarousel';
import ProductCategoryCarousel from '../components/ProductCategoryCarousel';
import HeroSlider from '../components/HeroSlider';
import Heading from '../components/Heading';
import ReactHtmlParser from 'react-html-parser';
import SubHeading from '../components/SubHeading';
import Wildcard from '../components/Wildcard';
import BrowseRecipes from '../components/BrowseRecipes';
import SocialMediaSection from '../components/SocialMediaSection';
import WhereToBuyModule from '../components/WhereToBuyModule';
import PopularRecipe from '../components/PopularRecipe';
import FeaturedRecipeCollection from '../components/FeaturedRecipeCollection';
import placeHolderImage from '../images/placeholder.png';
import InstagramFeed from '../components/InstagramFeed';
import { UiStateConsumer } from '../context/UiStateContext';

// Auth components
import { UserStateConsumer } from '../context/UserStateContext';
import FacebookAuthSignUp from '../components/Forms/FacebookAuthSignUp';
import GoogleAuthSignUp from '../components/Forms/GoogleAuthSignUp';

const Div = styled.div`
  .CarouselSection {
    text-align: center;
    padding: 50px 15px;
    margin-bottom: 30px;
    h1 {
      font-size: 30px;
      font-weight: bold;
      @media (min-width: ${breakpoints.generalDesktop}) {
        font-size: 36px;
      }
    }
    h2 {
      margin: 8px 0 0 0;
      font-size: 22px;
    }
    p {
      margin: 5px 0 20px 0;
    }
    .product-text h3 {
      padding-bottom: 0;
    }
    .productName {
    }
    .productDescription {
      margin-top: 12px;
      font-size: 18px;
    }
    .productName {
      color: ${colors.primary};
      font-size: 24px;
      padding: 0 30px;
      margin-bottom: 0;
    }
    .swiper-container .next-button svg,
    .swiper-container .prev-button svg {
      color: ${colors.primary};
    }
  }
  .content-section {
    height: 540px;
    text-align: center;
  }
  .MobileContent {
    h2 {
      text-align: center;
    }
  }
  .SocialSection {
    button {
      margin: 14px 0;
      width: 290px;
      padding: 15px 0 15px 30px;
      text-align: left;
      font-size: 18px;
      svg {
        width: 50px;
      }
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 0px;
      }
    }
    .email,
    .login {
      color: ${colors.bodyText};
    }
    .login {
      padding-left: 45px;
    }
    .sign-in {
      text-decoration: underline;
    }
  }
  .wildcard-background {
    background-repeat: no-repeat;
  }
  .profile-link,
  .profile-link button {
    color: #000 !important;
    text-decoration: none !important;
    text-align: center !important;
    padding-left: 0px !important;
  }
  .social-section-signed-in {
    text-align: center !important;
    color: #fff;
  }
  .wildcard-container {
    .left-content {
      h2 {
        font-size: 30px;
        @media (min-width: ${breakpoints.generalDesktop}) {
          font-size: 36px;
        }
      }
      p {
        font-size: 22px;
      }
    }
  }
  div[class*='FeaturedRecipeCollection'] {
    .featuredRecipeContainer {
      h2 {
        font-size: 30px;
        @media (min-width: ${breakpoints.generalDesktop}) {
          font-size: 36px;
        }
      }
    }
  }
  /* display no instagram feed for 3/11 presentation to American Beauty client */
  .insta-feed {
    display: none;
  }
  @media (min-width: ${breakpoints.desktop}) {
    .CarouselSection {
      padding: 35px 15px;
    }
    .WhereToBuy {
      display: none !important;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `${process.env.WP_BASE_URI}/${
        process.env.ACTIVE_BRAND
      }/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const {
      siteType,
      carouselType,
      data: {
        wordpressPage: {
          acf: {
            hero_slider,
            featured_recipe_collection,
            products_headline,
            products_sub_headline,
            wildcard_headline,
            wildcard_sub_headline,
            wildcard_cta_label,
            wildcard_cta_target,
            show_wildcard_block,
            join_headline,
            join_sub_headline,
            social_headline,
            social_handle,
            show_social_feed,
            recipe_search_headline,
            recipe_search_cta_label,
            recipe_search_sub_headline,
            wildcard_background_image,
            wildcard_background_image: { source_url },
            wtb_background_image,
            wtb_headline,
            wtb_section_copy
          }
        },
        wordpressWpOptionsPage: {
          acf: { product_category_carousel, product_carousel }
        },
        allWordpressTag
      }
    } = this.props;
    const wildcardBackground = this.props.data.wordpressPage.acf
      .wildcard_background_image.source_url;
    const socialBackground = this.props.data.wordpressPage.acf
      .join_background_image.source_url;
    const ph = this.props.data.wordpressPage.acf.products_headline;

    return (
      <Div>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <HeroSlider
          heroSlider={
            this.state.isPreviewMode
              ? this.state.previewPayload.acf.hero_slider
              : hero_slider
          }
          isPreviewMode={this.state.isPreviewMode}
        />

        <div className="CarouselSection">
          <div className="small-img" />

          <Heading>
            {this.state.isPreviewMode
              ? ReactHtmlParser(this.state.previewPayload.acf.products_headline)
              : ReactHtmlParser(
                  this.props.data.wordpressPage.acf.products_headline
                )}
          </Heading>
          <SubHeading>
            {this.state.isPreviewMode
              ? ReactHtmlParser(
                  this.state.previewPayload.acf.products_sub_headline
                )
              : ReactHtmlParser(products_sub_headline)}
          </SubHeading>
          {carouselType === 'product_category_carousel' && (
            <ProductCategoryCarousel
              carouselItems={product_category_carousel}
            />
          )}
        </div>
        <Wildcard
          title={ReactHtmlParser(wildcard_headline)}
          content={ReactHtmlParser(wildcard_sub_headline)}
          buttonText={ReactHtmlParser(wildcard_cta_label)}
          link={wildcard_cta_target}
          backgroundImage={wildcardBackground}
        />

        <BrowseRecipes
          headline={ReactHtmlParser(
            this.props.data.wordpressPage.acf.recipe_search_headline
          )}
          subHeadline={ReactHtmlParser(
            this.props.data.wordpressPage.acf.recipe_search_sub_headline
          )}
          ctaLabel={this.props.data.wordpressPage.acf.recipe_search_cta_label}
        />

        <UiStateConsumer>
          {uiStateContext => {
            return (
              <UserStateConsumer>
                {userContext => {
                  return (
                    <div className="SocialSection">
                      <SocialMediaSection
                        title={ReactHtmlParser(join_headline)}
                        content={ReactHtmlParser(join_sub_headline)}
                        backgroundImage={socialBackground}
                      >
                        <div
                          style={{
                            display: userContext.authenticatedUser
                              ? 'none'
                              : 'block'
                          }}
                        >
                          <FacebookAuthSignUp
                            authenticateUser={user =>
                              userContext.authenticateUser('facebook', user)
                            }
                          />
                          <GoogleAuthSignUp
                            authenticateUser={user =>
                              userContext.authenticateUser('google', user)
                            }
                          />

                          <button
                            className="email"
                            onClick={() => uiStateContext.toggleModal('signUp')}
                          >
                            <FontAwesomeIcon icon={faEnvelope} />
                            Sign Up with Email
                          </button>
                          <button
                            className="login"
                            onClick={() => uiStateContext.toggleModal('login')}
                          >
                            Have an Account? <a className="sign-in">Log In</a>
                          </button>
                        </div>
                        <div
                          className="social-section-signed-in"
                          style={{
                            display: userContext.authenticatedUser
                              ? 'block'
                              : 'none'
                          }}
                        >
                          <h2>
                            Welcome{' '}
                            {userContext.authenticatedUser &&
                            userContext.authenticatedUser.first_name
                              ? userContext.authenticatedUser.first_name + '!'
                              : 'Back!'}
                          </h2>
                          <Link to={`/user/profile`} className="profile-link">
                            <RegularButton>View Your Profile</RegularButton>
                          </Link>
                          <button
                            className="profile-link"
                            onClick={() => userContext.logout()}
                          >
                            Log Out
                          </button>
                        </div>
                      </SocialMediaSection>
                    </div>
                  );
                }}
              </UserStateConsumer>
            );
          }}
        </UiStateConsumer>
        <FeaturedRecipeCollection
          recipeCollection={
            this.state.isPreviewMode
              ? this.state.previewPayload.acf.featured_recipe_collection
              : featured_recipe_collection
          }
        />
        {/*<WhereToBuyModule
          background={
            this.state.isPreviewMode
              ? this.state.previewPayload.acf.wtb_background_image.url
              : wtb_background_image.source_url
          }
          headline={
            this.state.isPreviewMode
              ? this.state.previewPayload.acf.wtb_headline
              : wtb_headline
          }
          subHeadline={
            this.state.isPreviewMode
              ? this.state.previewPayload.acf.wtb_section_copy
              : wtb_section_copy
          }
        />*/}
      </Div>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        products_headline
        products_sub_headline
        wildcard_headline
        wildcard_sub_headline
        wildcard_cta_label
        wildcard_cta_target
        show_wildcard_block
        join_headline
        join_sub_headline
        social_headline
        social_handle
        recipe_search_headline
        recipe_search_cta_label
        recipe_search_sub_headline
        wildcard_background_image {
          source_url
        }
        join_background_image {
          source_url
        }
        # product_category_carousel {
        #   name
        # }
        hero_slider {
          cta_link
          cta_text
          image {
            source_url
          }
          v_align
        }
        featured_recipe_collection {
          post_title
          post_name
          post_content
        }
        wtb_background_image {
          source_url
        }
        wtb_headline
        wtb_section_copy
      }
    }
    wordpressWpOptionsPage(slug: { eq: "sitewide-components" }) {
      acf {
        product_carousel {
          wordpress_id
          post_name
          post_title
        }
        product_category_carousel {
          slug
          term_id
          term_taxonomy_id
          name
        }
      }
    }
    allWordpressTag {
      edges {
        node {
          wordpress_id
          slug
        }
      }
    }
  }
`;
