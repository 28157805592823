import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import ComponentStyles from '../brand-styles/components/BrowseRecipes.styles';
import clock from './../images/clock.svg';
import pasta from './../images/pasta.svg';
import ingredient from './../images/ingredient.svg';
import sauce from './../images/sauce.svg';
import method from './../images/method.svg';
import meal from './../images/meal.svg';
import RegularButton from './RegularButton';
import PagesHero from '../components/PagesHero';
import HeadingLeft from '../components/HeadingLeft';
import { breakpoints, colors } from '../style-utilities/variables';

const Div = styled.div`
  height: auto;
  .WildcardContainer {
    text-align: center;
    padding: 70px 15px;
    .hide-mobile {
      display: none;
    }
    .LeftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      h2 {
        font-size: 28px;
      }
      p {
        margin-bottom: 26px;
      }
    }
    h3 {
      color: ${colors.primary};
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .icon-text {
      display: flex;
      align-items: center;
      max-width: 320px;
      margin: 0 auto;
      img.icon-bg {
        height: 80px;
        width: 80px;
      }
      img.icon-med {
        height: 80px;
        width: 80px;
        padding: 6px;
      }
      img.icon-small {
        height: 80px;
        width: 80px;
        padding: 16px;
      }
      .text {
        text-align: left;
        padding-left: 20px;
        .text-title {
          font-weight: bold;
          color: ${colors.primary};
          margin-bottom: 0;
        }
        .text-line {
          margin-top: 8px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .WildcardContainer {
      margin: 0 auto;
      display: flex;
      padding: 60px;
      max-width: ${breakpoints.maxContainer};
      .hide-desktop {
        display: none;
      }
      .hide-mobile {
        display: inline-block;
      }
      .LeftContent {
        width: 400px;
        height: 500px;
        border-right: 1px solid lightgray;
        padding-right: 30px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .right-content {
      padding-left: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        text-align: left;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .left-content {
      width: 420px;
    }
    .time {
      grid-area: time;
    }
    .sauce {
      grid-area: sauce;
    }
    .ingredients {
      grid-area: ingredients;
    }
    .method {
      grid-area: method;
    }
    .meal {
      grid-area: meal;
    }
    .product {
      grid-area: product;
    }

    .grid-items {
      display: grid;
      display: -ms-grid;
      -ms-grid-columns: 1fr 30px 1fr;
      -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-areas:
        'product time'
        'ingredients method'
        'sauce meal';
      column-gap: 30px;

      .product {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      .time {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
      }
      .ingredient {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
      .method {
        -ms-grid-row: 2;
        -ms-grid-column: 3;
      }
      .sauce {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }
      .meal {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
      }
    }
  }

  @media (min-width: 1400px) {
    padding: 0;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;
const BrowseRecipes = ({ headline, subHeadline, ctaLabel }) => {
  return (
    <Div>
      <div className="WildcardContainer">
        <div className="LeftContent">
          <HeadingLeft>{headline}</HeadingLeft>
          <p>{ReactHtmlParser(subHeadline)}</p>
          <div className="hide-desktop">
            <hr />
          </div>
          <div className="hide-mobile">
            <Link to="/recipe-search">
              <RegularButton>{ctaLabel}</RegularButton>
            </Link>
          </div>
        </div>
        <div className="right-content">
          <h3>Search Recipes by:</h3>
          <div className="grid-items">
            <div className="icon-text product">
              <img className="icon-bg" src={pasta} alt="" />
              <div className="text">
                <p className="text-title">Pasta Shape</p>
                <p className="text-line">Spaghetti, Elbows and More</p>
              </div>
            </div>
            <div className="icon-text time">
              <img className="icon-small" src={clock} alt="" />
              <div className="text">
                <p className="text-title">Time</p>
                <p className="text-line">Quick &amp; Easy Recipes</p>
              </div>
            </div>
            <div className="icon-text ingredient">
              <img className="icon-med" src={ingredient} alt="" />
              <div className="text">
                <p className="text-title">Main Ingredient</p>
                <p className="text-line">Recipes by ingredient</p>
              </div>
            </div>
            <div className="icon-text method">
              <img className="icon-bg" src={method} alt="" />
              <div className="text">
                <p className="text-title">Cook Method</p>
                <p className="text-line">
                  Oven-baked dishes to stove-top tosses
                </p>
              </div>
            </div>
            <div className="icon-text sauce">
              <img className="icon-med" src={sauce} alt="" />
              <div className="text">
                <p className="text-title">Sauce Varieties</p>
                <p className="text-line">Red, White, Oil, Pesto &amp; More</p>
              </div>
            </div>
            <div className="icon-text meal">
              <img className="icon-bg" src={meal} alt="" />
              <div className="text">
                <p className="text-title">Meal Part</p>
                <p className="text-line">Recipes for any course</p>
              </div>
            </div>
            <div className="hide-desktop">
              <Link to="/recipe-search">
                <RegularButton>{ctaLabel}</RegularButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Div>
  );
};

export default BrowseRecipes;
