import { css } from 'styled-components';

export default css`
  button {
    background: transparent;
  }
  .swiper-container {
  }
  .swiper-wrapper {
  }
  .swiper-slide {
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    background-position: top center !important;
  }
  .prev-button,
  .next-button {
    background: transparent;
  }
`;
