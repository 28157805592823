import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/HeroSlider.styles';
import Swiper from 'react-id-swiper/lib';
import { breakpoints, colors } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import StyledLink from '../components/StyledLink';

const StyledHero = styled.div`
  .swiper-container,
  .InnerBox {
    text-align: center;
    height: 650px;
  }
  .InnerBox {
    padding: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    #cta {
      color: white;
      background: ${colors.primary};
      font-size: 20px;
      padding: 10px 40px;
      text-decoration: none;
      display: inline-block;
      &:hover {
        opacity: 0.9;
        transition: background-color 0.3s ease-out;
      }
    }
  }
  .prev-button,
  .next-button {
    display: none;
  }
  .noTitleBox {
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .prev-button,
    .next-button {
      display: none;
    }
    .prev-button,
    .next-button {
      display: block;
    }
  }
  @media (max-width: ${breakpoints.desktop}) {
    .swiper-container,
    .InnerBox {
      height: 450px;
    }
    .Innerbox {
      justify-content: center;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NavigatioButton = styled.button`
  position: absolute;
  top: 47%;
  right: ${props => props.right};
  left: ${props => props.left};
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-color: transparent;
  color: #d2d4dc;
  font-size: 40px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.desktop}) {
    top: 140px;
  }
  /* @media (max-width: ${breakpoints.mobileMax}) {
    .next-button, .prev-button {
      width: .3em;
    }
  } */
`;

const HeroSlider = ({ heroSlider, isPreviewMode }) => {
  const params = {
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1
    },
    loop: true,
    autoplay: {
      delay: 5000
    },
    type: 'bullets',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.next-button',
      prevEl: '.prev-button'
    },
    renderNextButton: () => (
      <NavigatioButton className="next-button" right="10px">
        <FontAwesomeIcon icon={faAngleRight} />
      </NavigatioButton>
    ),
    renderPrevButton: () => (
      <NavigatioButton className="prev-button" left="10px">
        <FontAwesomeIcon icon={faAngleLeft} />
      </NavigatioButton>
    )
  };
  //Condition: if there are more than 1 slide
  const condition = heroSlider.length > 1;
  //Content for hero slider
  const content = heroSlider.map(
    ({ cta_link, cta_text, title, description, image, v_align }, idx) => {
      return (
        <div
          id={'swiper-style-override-' + idx}
          key={idx}
          style={{
            background: `${
              idx === 0 ? 'url(' + image.source_url + ')' : 'initial'
            }`,
            backgroundSize: 'cover',
            backgroundPosition: 'center ' + `${v_align ? v_align : 'center'}`,
            backgroundRepeat: 'no-repeat'
          }}
          data-background={isPreviewMode ? image.url : image.source_url}
          className="swiper-lazy"
        >
          {title === '' &&
          description === '' &&
          cta_link === '' &&
          cta_text === '' ? (
            ''
          ) : (
            <>
              {title === '' ? '' : <div className="smokeBlur" />}
              {title !== '' ? (
                <div className="InnerBox">
                  <div className="title">
                    <h1>{ReactHtmlParser(title)}</h1>
                    <p className="HeroSlider-P">
                      {ReactHtmlParser(description)}
                    </p>
                  </div>
                  {cta_link !== '' && cta_text !== '' ? (
                    <div className="hero-button">
                      <a id="cta" href={cta_link}>
                        {ReactHtmlParser(cta_text)}
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <a href={cta_link}>
                  <div className="noTitleBox">
                    {cta_text !== '' ? (
                      <div className="hero-button">
                        <a id="cta" href={cta_link}>
                          {ReactHtmlParser(cta_text)}
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>{' '}
                </a>
              )}
            </>
          )}
        </div>
      );
    }
  );
  return (
    <StyledHero>
      {condition ? (
        <Swiper {...params} className="Hero">
          {content}
        </Swiper>
      ) : (
        <div className="Hero">{content}</div>
      )}
    </StyledHero>
  );
};
export default HeroSlider;

export const heroSliderQuery = graphql`
  fragment HeroSliderFragment on wordpress__PAGE {
    acf {
      hero_slider {
        image {
          source_url
        }
        v_align
        title
        cta_text
        cta_link
      }
    }
  }
`;
