import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/ProductCarousel.styles';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';

import { breakpoints, colors } from '../style-utilities/variables';
import RegularButton from './RegularButton';

const StyledCarousel = styled.div`
  margin-top: 10px;
  .svg-inline--fa.fa-w-8 {
    width: 0.7em;
  }
  .next-button svg,
  .prev-button svg {
    color: #333333;
  }

  .next-button,
  .prev-button {
    outline: none;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;
const NavigationButton = styled.button`
  position: absolute;
  top: 37%;
  right: ${props => props.right};
  left: ${props => props.left};
  margin-top: -22px;
  z-index: 50;
  cursor: pointer;
  background-color: transparent;
  color: #d2d4dc;
  font-size: 40px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.desktop}) {
    top: 50%;
  }
`;

const StyledProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  min-height: 350px;
  margin-bottom: 0;
  width: 250px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  .image-box {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-text {
    width: 250px;
    /* height: 350px; */
    margin: auto;
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 24px;
      padding: 12px 0 6px 0;
      display: block;
      color: ${colors.primary};
    }
    p {
      padding: 0;
      color: grey;
      letter-spacing: 0.4px;
    }
  }

  &:hover {
    bottom: 10px;
    position: relative;
    transition: bottom 2s ease-out;
  }
  img {
    height: auto;
    width: 250px;
    margin-top: 20px;
  }
`;

class ProductCarousel extends Component {
  state = {
    carouselProducts: []
  };

  BASE_URL = `${process.env.WP_BASE_URI}/${
    process.env.ACTIVE_BRAND
  }/wp-json/wp/v2/`;

  componentDidMount() {
    const { carouselItems } = this.props;
    const nameList = [...carouselItems.map(el => el.post_name)].join(',');

    fetch(`${this.BASE_URL}products?slug=${nameList}`)
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({ carouselProducts: data });
      })
      .catch(err => console.log(err));
  }
  render() {
    const params = {
      loop: true,
      type: 'bullets',
      slidesPerView: 4,
      spaceBetween: 10,
      navigation: {
        nextEl: '.next-button',
        prevEl: '.prev-button'
      },
      renderNextButton: () => (
        <NavigationButton className="next-button" right="-10px">
          <FontAwesomeIcon icon={faAngleRight} />
        </NavigationButton>
      ),
      renderPrevButton: () => (
        <NavigationButton className="prev-button" left="-10px">
          <FontAwesomeIcon icon={faAngleLeft} />
        </NavigationButton>
      ),
      breakpoints: {
        1300: {
          slidesPerView: 3
        },
        1050: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 1
        }
      }
    };
    return (
      <StyledCarousel>
        {this.props.children}
        {this.state.carouselProducts.length > 0 ? (
          <Swiper {...params}>
            {this.state.carouselProducts.map(
              ({ slug, id, title, content, acf: { product_image } }) => {
                return (
                  <StyledProduct key={id}>
                    <Link to={`/products/${slug}`}>
                      <div className="image-box">
                        <img
                          src={product_image}
                          alt="productImg"
                          className="prod-img"
                        />
                      </div>
                      <div className="product-text">
                        <span className="title">
                          {ReactHtmlParser(title.rendered)}
                        </span>
                        <div className="description">
                          {ReactHtmlParser(content.rendered)}
                        </div>
                      </div>
                    </Link>
                  </StyledProduct>
                );
              }
            )}
          </Swiper>
        ) : (
          <div>...Loading</div>
        )}

        <div className="Button">
          <Link to="/products">
            <RegularButton>
              {this.props.CTAtext
                ? this.props.CTAtext.map((el, index) => (
                    <span key={index}>{el}</span>
                  ))
                : 'See All Products'}
            </RegularButton>
          </Link>
        </div>
      </StyledCarousel>
    );
  }
}

export default ProductCarousel;

// export const productCarousel = graphql`
//   fragment ProductCarouselFragment on wordpress__wp_options_page {
//     acf {
//       product_carousel {
//         post_title
//         wordpress_id
//         acf {
//           product_image {
//             url
//           }
//         }
//       }
//     }
//   }
// `;
