import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Forms/FacebookAuth.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
const StyledFacebookAuthSignUp = styled.div`
  .facebook {
    background: #3b5998;
    color: white;
    display: block;
    width: 300px;
    padding: 12px 0;
    margin: 10px auto;
    border: none;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const FacebookAuthSignUp = ({ authenticateUser }) => {
  return (
    <StyledFacebookAuthSignUp>
      <FacebookLogin
        appId={process.env.FACEBOOK_AUTH_APP_ID}
        autoLoad={false}
        fields="id,name,email,picture.width(100).height(100)"
        callback={authenticateUser}
        render={renderProps => (
          <button className="facebook" onClick={renderProps.onClick}>
            <FontAwesomeIcon icon={faFacebookF} /> Sign Up with Facebook
          </button>
        )}
      />
    </StyledFacebookAuthSignUp>
  );
};

export default FacebookAuthSignUp;
