import { css } from 'styled-components';
import rose from '../../images/rose_75x73.png';
import { colors, rosebud } from '../../style-utilities/variables';

export default css`
  .WhereToBuy button {
    margin: auto;
  }
  .small-img {
    background-image: url(${rose});
    height: ${rosebud.height};
    width: ${rosebud.width};
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
  }
`;
