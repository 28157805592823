import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/HeadingLeft.styles';

import { breakpoints, colors, fonts } from '../style-utilities/variables';

const StyledHeadingLeft = styled.h2`
  color: ${colors.primary};
  font-size: 36px;
  font-family: ${fonts.primary};
  margin-bottom: 0;
  @media (max-width: ${breakpoints.mobileMax}) {
    font-size: 26px;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const HeadingLeft = ({ children, ...remProps }) => {
  return <StyledHeadingLeft {...remProps}>{children}</StyledHeadingLeft>;
};

export default HeadingLeft;
