import { css } from 'styled-components';
import { fonts, breakpoints } from '../../style-utilities/variables';
export default css`
  .text-title {
    font-size: 24px;
    font-weight: 700;
    font-family: ${fonts.secondary}, sans-serif;
  }
  .icon-text {
    width: 100%;
  }
  .LeftContent {
    p {
      font-size: 22px;
    }
  }
  .WildcardContainer .LeftContent {
    h2 {
      font-size: 30px;
      @media (min-width: ${breakpoints.generalDesktop}) {
        font-size: 36px;
      }
    }
  }
`;
