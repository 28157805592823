import { css } from 'styled-components';
import { colors, fonts, breakpoints } from '../../style-utilities/variables';
export default css`
  #swiper-style-override-1 {
    .hero-button a#cta {
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  .smokeBlur {
    display: none;
  }
  .InnerBox {
    position: relative;
    justify-content: flex-end !important;
    .hero-button #cta {
      padding: 10px 40px 5px;
      font-family: ${fonts.secondaryBold};
      background: ${colors.white};
      color: ${colors.primary};
    }
  }
  .swiper-container .next-button svg,
  .swiper-container .prev-button svg {
    color: ${colors.primary};
  }
  .swiper-container .swiper-pagination-bullet-active {
    background: ${colors.primary};
  }
`;
