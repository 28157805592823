import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import axios from 'axios';
import Swiper from 'react-id-swiper/lib';

import ComponentStyles from '../brand-styles/components/InstagramFeed.styles';
import { breakpoints } from '../style-utilities/variables';
import Heading from './Heading';
import SubHeading from './SubHeading';

const StyledInstaCard = styled.div`
  display: inline-block;
  margin: 20px 15px;
  img {
    width: 300px;
    border-radius: 10px;
  }
  img:hover {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.5);
  }
`;

const Div = styled.div`
  padding: 0;
  height: auto;
  text-align: center;
  .handle {
    text-transform: lowercase;
  }
  .handle a {
    text-decoration: none;
  }
  .Mobile {
    display: none;
  }
  @media (max-width: ${breakpoints.mobileMax}) {
    .Desktop {
      display: none !important;
    }
    .Mobile {
      display: block !important;
    }
    ${StyledInstaCard} {
      margin: 10px auto 30px auto !important;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class InstagramFeed extends Component {
  state = {
    feeds: [],
    url: ''
  };
  siteTitle = this.props.siteTitle;

  async componentDidMount() {
    const data = [];
    let URL;
    let instagramSource;
    if (this.siteTitle === 'success') {
      URL = 'https://www.instagram.com/successrice/';
    } else if (this.siteTitle === 'minute') {
      URL = 'https://www.instagram.com/minutericeus/';
    } else if (
      this.siteTitle === 'mahatma' ||
      this.siteTitle === 'mahatma-es'
    ) {
      URL = 'https://www.instagram.com/mahatmariceusa/';
    } else if (this.siteTitle === 'carolina') {
      URL = 'https://www.instagram.com/carolinariceusa/';
    } else if (this.siteTitle === 'no-yolks') {
      URL = 'https://www.instagram.com/noyolksnoodles/';
    }

    instagramSource = await axios.get(URL);

    // instagramSource.data contains the HTML from Axios
    const jsonObject = instagramSource.data
      .match(
        /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/
      )[1]
      .slice(0, -1);
    const instaObject = JSON.parse(jsonObject);
    const mediaArray = instaObject.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(
      0,
      4
    );
    mediaArray.map(media => {
      const node = media.node;
      // Push node to the array
      data.push(node);
    });
    this.setState({ feeds: data, url: URL });
  }

  renderInstaFeeds = () => {
    return this.state.feeds.map(feed => {
      return (
        <StyledInstaCard key={feed.shortcode} className="insta-card">
          {/* {feed.is_video === false ? ( */}
          <a
            className="InstaBox"
            href={`https://www.instagram.com/p/${feed.shortcode}/`}
            target="_blank"
          >
            <img src={feed.thumbnail_src} alt="instagram" />
          </a>
          {/* ) : (
            'Video'
          )} */}
        </StyledInstaCard>
      );
    });
  };

  render() {
    const { title, handle, ...remainProps } = this.props;
    const settings = {
      loop: true,
      speed: 500,
      slidesToShow: 1,
      type: 'bullets',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    };
    return (
      <Div {...remainProps}>
        <Heading className="heading">{title}</Heading>
        <SubHeading className="handle">
          <a href={this.state.url ? this.state.url : ''} target="_blank">
            {ReactHtmlParser(handle)}
          </a>
        </SubHeading>
        <div className="Desktop">{this.renderInstaFeeds()}</div>
        <div className="Mobile">
          <Swiper {...settings}>{this.renderInstaFeeds()}</Swiper>
        </div>
      </Div>
    );
  }
}

export default InstagramFeed;
