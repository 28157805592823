import { css } from 'styled-components';
import { colors, breakpoints } from '../../style-utilities/variables';
export default css`
  .LeftContent h1 {
    color: ${colors.primary};
    font-size: 36px;
  }

  button {
    background: ${colors.primary};
    color: white;
    &:hover {
      background: ${colors.primaryHover};
    }
  }
`;
