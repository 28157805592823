import { css } from 'styled-components';

export default css`
  .Content {
    h2 {
      color: #c20f2f;
    }
    button {
      color: white;
      background: #c20f2f;
      padding: 10px 40px;
    }
  }
  .DesktopContent {
    p {
      font-size: 22px;
    }
  }
`;
