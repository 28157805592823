import { css } from 'styled-components';
import { fonts, breakpoints } from '../../style-utilities/variables';
export default css`
  box-shadow: inset 0px 0px 150px rgba(0, 0, 0, 0.6);
  .social-container {
    .banner {
      .socialMediaSection-title {
        font-family: ${fonts.primary}, 'sans-serif';
        color: #fff;
        margin-bottom: 0;
        @media (max-width: ${breakpoints.generalDesktop}) {
          font-size: 30px;
        }
      }
      .sm-p {
        font-size: 22px;
        color: #fff;
      }
    }
  }
`;
