/**
 * Placeholder Variables
 * Change values to use in brand styling
 */

// colors
export const colors = {
  primary: 'rgb(0, 0, 0)',
  secondary: '#ffde00',
  primaryHover: 'rgba(0,0,0, 0.7)',
  grey: '#D1D4D3',
  darkGrey: '#303030',
  black: 'rgb(0, 0, 0)',
  white: '#fff',
  whiteHover: 'rgba(255, 255, 255, 0.8)',
  neonPink: '#fd5fac',
  hotPink: 'rgb(255, 0, 222)'
};

// breakpoints
export const breakpoints = {
  mobile: '480px',
  mobileMax: '767px',
  tablet: '768px',
  generalDesktop: '900px',
  desktop: '1100px',
  maxContainer: '1400px'
};

export const fonts = {
  title: "'ProximaNova Bold', sans-serif",
  primary: "'ProximaNova Bold', sans-serif",
  secondary: "'ProximaNova SemiBold','Trebuchet MS','Arial',sans-serif",
  semiBold: "'ProximaNova SemiBold','Trebuchet MS','Arial',sans-serif",
  body: "'ProximaNova Regular','Trebuchet MS','Arial',sans-serif",
  regular: "'ProximaNova Regular','Trebuchet MS','Arial',sans-serif",
  light: "'ProximaNova Light','Trebuchet MS','Arial',sans-serif"
};

export const fontSize = {
  headerMobile: '22px',
  header: '38px',
  subHeaderMobile: '16px',
  subHeader: '20px'
};
//unique to NoYo
export const neonEffect = {
  headlineGlow: '0 0 8px #fff, 2px 2px 2px rgba(55, 55, 55, 0)',
  subHeadlineGlow: '0 0 3px #fff, 2px 2px 2px rgba(55, 55, 55, 0)'
};
