import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/Wildcard.styles';
import { breakpoints, colors } from '../style-utilities/variables';
import StyledLink from './StyledLink';
import HeadingLeft from './HeadingLeft';
import { Link } from 'gatsby';
import RegularButton from './RegularButton';

const StyledWildcard = styled.div`
  .wildcard-container {
    margin: 0 auto;
    max-width: ${breakpoints.maxContainer};
    padding: 0 60px;
    .left-content {
      width: 300px;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 26px;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    height: auto;
    padding: 0;
    .wildcard-container {
      padding: 0 15px 36px;
      .left-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const Wildcard = ({
  title,
  content,
  buttonText,
  link,
  backgroundImage,
  ...remainProps
}) => {
  return (
    <StyledWildcard
      {...remainProps}
      // className="wildcard-background"
      style={{
        background: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
      }}
    >
      <div className="wildcard-container">
        <div className="left-content">
          <HeadingLeft>{title}</HeadingLeft>
          <p>{ReactHtmlParser(content)}</p>
          <Link to={link}>
            <RegularButton link={link}>{buttonText}</RegularButton>
          </Link>
        </div>
      </div>
    </StyledWildcard>
  );
};

export default Wildcard;
