import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/SocialMediaSection.styles';

import { breakpoints, colors } from '../style-utilities/variables';

const Div = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  p {
    color: #000;
    margin-top: 8px;
    font-size: 22px;
  }
  .social-container {
    text-align: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
  }

  .socialButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      border: none;
      background: white;
      display: block;
      width: 270px;
      padding: 12px 0;
      text-align: left;
      margin: 5px auto;
      cursor: pointer;
    }
    .facebook {
      background: #3b5998;
      color: white;
    }
    .google {
      background: #db3236;
      color: white;
    }
    .login-button {
      padding-left: 26px;
      a {
        text-decoration: underline;
      }
    }
    svg {
      width: 50px;
    }
  }

  .banner {
    grid-area: banner;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 40px;

    .socialMediaSection-title {
      font-size: 36px;
      font-weight: 700;
    }
  }
  .social-container {
    .banner {
      .sm-title {
        font-size: 36px;
        p {
          text-align: left;
        }
      }
    }
    p {
      text-align: left;
    }
  }
  .social-container .banner .sm-p {
    font-size: 18px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .socialButtons {
      display: block;
      button {
        margin: 8px 0;
      }
    }
    .social-container {
      text-align: left;
      height: 420px;
      padding: 60px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .banner {
      max-width: 562px;
      padding-bottom: 0;
      margin-right: 30px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const SocialMediaSection = ({
  children,
  title,
  content,
  backgroundImage,
  siteTitle,
  ...remainProps
}) => {
  return (
    <Div
      {...remainProps}
      style={{
        background: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
      }}
    >
      <div className="social-container">
        <div className="banner">
          <span className="socialMediaSection-title sm-title">{title}</span>
          <p className="sm-p">{content}</p>
        </div>
        <div className="socialButtons">{children}</div>
      </div>
    </Div>
  );
};

export default SocialMediaSection;
