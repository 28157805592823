import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/PopularRecipe.styles';

import placeHolderImage from '../images/placeholder.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { breakpoints, colors } from '../style-utilities/variables';
import Heading from './Heading';
import SubHeading from './SubHeading';
import RegularButton from './RegularButton';
import RecipeCard from './RecipeCard';
import ReactHtmlParser from 'react-html-parser';
import Swiper from 'react-id-swiper/lib';

const StyledPopularRecipe = styled.div`
  .svg-inline--fa.fa-w-8 {
    width: 0.7em;
  }
  .next-button svg,
  .prev-button svg {
    color: gray;
  }

  .next-button,
  .prev-button {
    outline: none;
  }

  margin: 40px auto;
  .Recipes {
    text-align: center;
    position: relative;
    bottom: -1rem;
    .Button {
      display: block;
      margin: auto;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin: 100px auto;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 47%;
  right: ${props => props.right};
  left: ${props => props.left};
  margin-top: -22px;
  z-index: 50;
  cursor: pointer;
  background-color: transparent;
  color: #d2d4dc;
  font-size: 40px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.desktop}) {
    top: 50%;
  }
`;

const SingleRecipe = styled.div`
  display: inline-block;
`;

const recipes = [
  {
    id: 'rep-1',
    source: placeHolderImage,
    title: 'Recipe Title that will wrap to 2'
  },
  {
    id: 'rep-2',
    source: placeHolderImage,
    title: 'Recipe Title that will wrap to 2'
  },
  {
    id: 'rep-3',
    source: placeHolderImage,
    title: 'Recipe Title that will wrap to 2'
  },
  {
    id: 'rep-4',
    source: placeHolderImage,
    title: 'Recipe Title that will wrap to 2'
  }
];

class PopularRecipe extends Component {
  renderRecipe = () => {
    return recipes.map(recipe => {
      return (
        <SingleRecipe key={recipe.id}>
          <RecipeCard
            title={ReactHtmlParser(recipe.title)}
            imageUrl={recipe.sourcs}
            cookTime="20 mins"
            mainIngredient="chicken"
          />
        </SingleRecipe>
      );
    });
  };
  render() {
    const params = {
      loop: true,
      slidesPerView: 1,
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'bullets',
      //   clickable: true
      // },
      navigation: {
        nextEl: '.next-button',
        prevEl: '.prev-button'
      },
      renderNextButton: () => (
        <NavigationButton className="next-button" right="-1px">
          <FontAwesomeIcon icon={faAngleRight} />
        </NavigationButton>
      ),
      renderPrevButton: () => (
        <NavigationButton className="prev-button" left="-1px">
          <FontAwesomeIcon icon={faAngleLeft} />
        </NavigationButton>
      )
    };
    return (
      <StyledPopularRecipe>
        <Heading>{ReactHtmlParser(this.props.heading)}</Heading>
        <SubHeading>{ReactHtmlParser(this.props.subheading)}</SubHeading>
        <div className="Recipes">
          <Swiper {...params}>{this.renderRecipe()}</Swiper>
          <div className="Button">
            <RegularButton>{this.props.buttonText}</RegularButton>
          </div>
        </div>
      </StyledPopularRecipe>
    );
  }
}

export default PopularRecipe;
