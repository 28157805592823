import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ComponentStyles from '../brand-styles/components/RegularButton.styles';
import {
  fonts,
  colors,
  neonEffect,
  breakpoints
} from '../style-utilities/variables';
import neonArrows from '../images/neonArrows.png';

export const Button = styled.button`
  text-decoration: none;
  display: inline-block;
  outline: none;
  background-image: url(${neonArrows});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 33px;
  padding-right: 38px;
  padding-top: 2px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: ${fonts.regular};
  font-size: 17px;
  &:hover {
    text-shadow: ${neonEffect.headlineGlow};
    transition: background-color 0.3s ease-out;
  }
  &:disabled {
    background-color: gray;
    &:hover {
      background-color: gray;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 26px;
    background-size: 45px;
    padding-right: 50px;
    padding-top: 4px;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const NeonArrowsButton = ({ link, children, ...remainProps }) => {
  return <Button {...remainProps}>{children}</Button>;
};

export default NeonArrowsButton;
