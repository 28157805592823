import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/SubHeading.styles';

import { breakpoints } from '../style-utilities/variables';

const Sub = styled.h2`
  font-size: 30px;
  font-weight: 500;
  margin-top: 14px;
  padding: 0 15px;
  text-align: center;
  @media (max-width: ${breakpoints.desktop}) {
    font-size: 18px;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const SubHeading = ({ children, ...remainProps }) => {
  return <Sub {...remainProps}>{children}</Sub>;
};

export default SubHeading;
