import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ComponentStyles from '../brand-styles/components/FeaturedRecipeCollection.styles';
import RegularButton from './RegularButton';
import NeonArrowsButton from '../../src_no-yolks/components/NeonArrowsButton';
import HeadingLeft from './HeadingLeft';
import Heading from './Heading';
import SubHeading from './SubHeading';
import { stripHtmlTag } from '../utilities/stripHtmlTagUtils';

import { breakpoints } from '../style-utilities/variables';
import RecipeCollectionCard from './RecipeCollectionCard';

const StyledFeaturedRecipeCollection = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    .featuredRecipeContainer > *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    .featuredRecipeContainer > *:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .featuredRecipeContainer > *:nth-child(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }
    .featuredRecipeContainer > *:nth-child(4) {
      -ms-grid-row: 1;
      -ms-grid-column: 7;
    }
    .featuredRecipeContainer > *:nth-child(5) {
      -ms-grid-row: 1;
      -ms-grid-column: 9;
    }
    .featuredRecipeContainer > *:nth-child(6) {
      -ms-grid-row: 1;
      -ms-grid-column: 11;
    }
    .featuredRecipeContainer > *:nth-child(7) {
      -ms-grid-row: 1;
      -ms-grid-column: 13;
    }
    .featuredRecipeContainer > *:nth-child(8) {
      -ms-grid-row: 1;
      -ms-grid-column: 15;
    }
    .featuredRecipeContainer > *:nth-child(9) {
      -ms-grid-row: 1;
      -ms-grid-column: 17;
    }
    .featuredRecipeContainer > *:nth-child(10) {
      -ms-grid-row: 1;
      -ms-grid-column: 19;
    }
    .featuredRecipeContainer > *:nth-child(11) {
      -ms-grid-row: 1;
      -ms-grid-column: 21;
    }
    .featuredRecipeContainer > *:nth-child(12) {
      -ms-grid-row: 1;
      -ms-grid-column: 23;
    }
  }

  .featuredRecipeContainer {
    max-width: 1400px;
    height: 600px;
    margin: 0 auto;
    padding: 40px 15px;
    text-align: left;
    .Image {
      margin-bottom: 20px;
    }
    .DesktopContent {
      display: none;
      span {
        display: block;
      }
    }
    .MobileContent {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      text-align: left;
    }
    @media (min-width: ${breakpoints.tablet}) {
      .MobileContent {
        display: none;
      }
      display: -ms-grid;
      display: grid;
      grid-gap: 5vw;
      -ms-grid-columns: 1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw
        1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw 1fr;
      grid-template-columns: repeat(12, 1fr);
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      align-items: center;

      .Image {
        -ms-grid-column-span: 13;
        -ms-grid-column: 1;
        grid-column: span 7;
        -ms-grid-row: 1;
        grid-row: 1;
        text-align: right;
      }

      .DesktopContent {
        text-align: left;
        display: block;
        -ms-grid-column-span: 11;
        -ms-grid-column: 15;
        grid-column: span 5;
        -ms-grid-row: 1;
        grid-row: 1;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* // IE10+ CSS here */
    .featuredRecipeContainer {
      .DesktopContent {
        position: relative;
        top: 24%;
      }
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;
const FeaturedRecipeCollection = props => {
  const {
    recipeCollection: { post_name, post_title, post_content }
  } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpRecipes {
            edges {
              node {
                slug
                acf {
                  thumbnail {
                    source_url
                  }
                }
              }
            }
          }
          allWordpressWpRecipeCollections {
            edges {
              node {
                slug
                acf {
                  recipes {
                    recipe {
                      post_name
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        // Get all properties for the Current Collection
        const getSingleCollection = data.allWordpressWpRecipeCollections.edges.filter(
          item => item.node.slug === post_name
        );
        const recipesFromCollection = getSingleCollection[0].node.acf.recipes;
        // Get all ACF property for those recipes in the Current Collecton
        const fullAcfRecipes = data.allWordpressWpRecipes.edges.filter(el =>
          recipesFromCollection.some(
            item => item.recipe.post_name === el.node.slug
          )
        );
        return (
          <StyledFeaturedRecipeCollection>
            <div className="featuredRecipeContainer">
              <div className="MobileContent">
                <HeadingLeft>{ReactHtmlParser(post_title)}</HeadingLeft>
                <span>{ReactHtmlParser(post_content)}</span>
              </div>
              <div className="Image">
                <RecipeCollectionCard
                  recipes={fullAcfRecipes}
                  slug={post_name}
                />
              </div>
              <div className="MobileContent mobile-button">
                <Link to={`/recipe-collections/${post_name}`}>
                  {props.siteTitle !== 'no-yolks' ? (
                    <RegularButton>View this Collection</RegularButton>
                  ) : (
                    <NeonArrowsButton>View this Collection</NeonArrowsButton>
                  )}
                </Link>
              </div>
              <div className="DesktopContent">
                <HeadingLeft>{ReactHtmlParser(post_title)}</HeadingLeft>
                <span>{ReactHtmlParser(post_content)}</span>

                <Link to={`/recipe-collections/${post_name}`}>
                  {props.siteTitle !== 'no-yolks' ? (
                    <RegularButton>View this Collection</RegularButton>
                  ) : (
                    <NeonArrowsButton>View this Collection</NeonArrowsButton>
                  )}
                </Link>
              </div>
            </div>
          </StyledFeaturedRecipeCollection>
        );
      }}
    />
  );
};

export default FeaturedRecipeCollection;
