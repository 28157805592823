import React, { Component, Children } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { StaticQuery, graphql, Link } from 'gatsby';
import { breakpoints, colors, fonts } from '../style-utilities/variables';
import ComponentStyles from '../brand-styles/components/WhereToBuyModule.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
// THIS IS THE WHERE TO BUY FOUND ON THE HOMEPAGE
const Div = styled.div`
  background: ${props => `url(${props.bgImage}) no-repeat bottom center`};
  background-size: cover;
  margin-top: 40px;
  padding: 20px 15px;
  .moduleHeader {
    font-size: 36px;
    margin-bottom: 0;
    text-align: center;
    font-weight: 700;
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 28px;
    }
  }
  .content {
    span {
      display: block;
    }
    text-align: center;
    #icon {
      font-size: 35px;
    }
  }

  .options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
    input,
    select,
    button {
      width: 80%;
      margin: 0 auto 10px;
      height: 42px;
    }
    input::placeholder {
      color: ${colors.black};
    }
  }
  input {
    -webkit-appearance: none;
    width: 80%;
    display: block;
    margin-bottom: 20px;
    border: thin solid ${colors.black};
    border-radius: 2px;
  }
  input::placeholder {
    color: ${colors.black};
  }
  select {
    display: block;
    color: ${colors.black};
    background-color: white !important;
    border: thin solid ${colors.black};
    border-radius: 2px;
    font: inherit;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, #333333 50%),
      linear-gradient(135deg, #333333 50%, transparent 50%);
    background-position: calc(100% - 22px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 7px 7px, 7px 7px, 1.5em 1.5em;
    background-repeat: no-repeat;
  }

  .ps-widget {
    height: 43px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 8%;
    .content {
      padding-top: 50px;
      padding: 40px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 50px 0 0;
    min-height: 400px;
    width: 100vw;
    .content {
      #sub-headline {
        width: 50%;
        margin: 0 auto;
      }
    }

    .options {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
      input,
      select,
      button {
        width: 30%;
      }
    }
  }

  ${ComponentStyles}
`;

class WhereToBuyModule extends Component {
  state = {
    productLine: '',
    selectedProduct: '',
    productsUnderSelectedCategory: []
  };
  /**
   * Dynamic URL for all Brands
   */
  BASE_URL = `${process.env.WP_BASE_URI}/${
    process.env.ACTIVE_BRAND
  }/wp-json/wp/v2`;

  psKey = process.env.PS_KEY;

  async componentWillUnmount() {
    document
      .querySelectorAll('div.ps-lightbox')
      .forEach(e => e.parentNode.removeChild(e));
    document
      .querySelectorAll('div.ps-lightbox-background')
      .forEach(e => e.parentNode.removeChild(e));
  }

  /**
   * OnChange Method for first Dropdown including fectching and populating data for Second DropDown
   */
  productLineOnChange = event => {
    event.preventDefault();
    const value = event.target.value;
    this.setState({ productLine: value });
    //Fetching products under the Selected Product Line and set it to State - productsUnderSelectedCategory
    fetch(`${this.BASE_URL}/products?product_line=${value}&per_page=100`)
      .then(result => result.json())
      .then(data => this.setState({ productsUnderSelectedCategory: data }))
      .then(data => console.log(this.state.productsUnderSelectedCategory))
      .catch(err => console.log(err));
  };

  /**
   * OnChange Handler for Second Dropdown
   */
  selectProductOnChange = e => {
    e.preventDefault();
    this.setState({ selectedProduct: e.target.value }, () =>
      PriceSpider.rebind()
    );
  };
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpProductLine {
              edges {
                node {
                  wordpress_id
                  name
                  slug
                }
              }
            }
          }
        `}
        render={data => {
          /**
           * Data from CMS: all Product Categories
           */
          const productLines = data.allWordpressWpProductLine.edges;
          /**
           * Method: Render Categories to the First Dropdown
           */
          const renderCategories = () => {
            return (
              <select
                name="productLine"
                value={this.state.productLine}
                onChange={this.productLineOnChange}
              >
                <option value="">Select a Category</option>
                {productLines.map(({ node: { name, wordpress_id } }) => {
                  return (
                    <option value={wordpress_id} key={wordpress_id}>
                      {ReactHtmlParser(name)}
                    </option>
                  );
                })}
              </select>
            );
          };
          const productsUnderSelectedCategory = this.state.productsUnderSelectedCategory.map(
            item => {
              return (
                <option value={item.acf.product_sku} key={item.slug}>
                  {ReactHtmlParser(item.title.rendered)}
                </option>
              );
            }
          );
          const bgImage = this.props.background;
          return (
            <Div bgImage={bgImage} className={this.props.className}>
              <Helmet>
                <meta name="ps-key" content={this.psKey} />
                <meta name="ps-country" content="US" />
                <meta name="ps-language" content="en" />
                <script
                  src="https://cdn.pricespider.com/1/lib/1.12.1/ps-widget.js"
                  async
                />
              </Helmet>
              <div className="content">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  id="icon"
                  style={{ height: '35px', width: '26px' }}
                />
                <span className="moduleHeader">
                  {this.props.headline
                    ? ReactHtmlParser(this.props.headline)
                    : this.props.children}
                </span>
                <span className="moduleSubHeadline">
                  {ReactHtmlParser(this.props.subHeadline)}
                </span>
                <div className="options">
                  {renderCategories()}
                  <select
                    name="selectedProduct"
                    value={this.state.selectedProduct}
                    onChange={this.selectProductOnChange}
                    disabled={this.state.productLine === ''}
                  >
                    <option>Select a Product</option>
                    {productsUnderSelectedCategory}
                  </select>
                  {this.state.selectedProduct ? (
                    <div
                      className="ps-widget"
                      ps-sku={this.state.selectedProduct}
                    />
                  ) : null}
                </div>
              </div>
            </Div>
          );
        }}
      />
    );
  }
}

export default WhereToBuyModule;
